<template>
	<DataTable
		:getQuery="queries.get"
		:createQuery="queries.create"
		:removeQuery="queries.remove"
		:updateQuery="queries.update"
		:headers="headers"
		:createFields="createFields"
		icon="mdi-tag"
		result_name="productTypes"
		title="product"
		subtitle="List of all products"
	>
	</DataTable>
</template>
<script>
import DataTable from '@/components/datatable'
export default {
	components: {DataTable},
    data: () => ({
		queries: require('@/gql/productType.gql'),
		createFields: [
			{ name:'name', label: 'Product Type Name', icon: 'mdi-tag'},
		],		
		headers: [
			{ text: 'Name', align: 'left', value: 'name' },
			{ text: 'Date Added', align: 'left', value: 'createdAt' },
			{
				text: 'Category', 
				value: 'button', 
				button: {
					label: 'view',
					icon: 'mdi-tag-multiple',
					color: 'grey',
					text: true,
					rounded: true,
					to: {
						name: 'admin-product-category',
						params: {key: 'id', value: 'id'}
					} 
				},
				align : 'center', 
				sortable: false,
			},
			{ text: 'Actions', value: 'action', sortable: false },
			],
    }),
}
</script>